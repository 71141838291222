import { CH_HOME_FEATURE } from '@propertypal/shared/src/constants/content-hub';
import useRecentSearches from '@propertypal/shared/src/hooks/useRecentSearches';
import { propertySearch } from '@propertypal/shared/src/reducers/properties';
import { initialState, setSearch } from '@propertypal/shared/src/reducers/search';
import { RootState, useAppDispatch } from '@propertypal/shared/src/reducers/store';
import { trackGaEvent, trackGaLink } from '@propertypal/shared/src/services/analytics';
import { HomepageTakeover } from '@propertypal/shared/src/types/homepageTakeover';
import { Property } from '@propertypal/shared/src/types/property';
import { ConvertedContentHubArticle } from '@propertypal/shared/src/types/strapi/overrides';
import { SuperFeatureProperty } from '@propertypal/shared/src/types/superFeature';
import platform from '@propertypal/shared/src/utils/platform';
import { Filters } from '@propertypal/shared/src/utils/search/getNlpFilters';
import ImageSlideshow from '@propertypal/web-ui/src/animation/ImageSlideshow';
import FeaturesTrio from '@propertypal/web-ui/src/home/FeaturesTrio';
import GuidesBar from '@propertypal/web-ui/src/home/GuidesBar';
import LatestNews from '@propertypal/web-ui/src/home/LatestNews';
import SimilarProperties from '@propertypal/web-ui/src/property/SimilarProperties';
import Takeover from '@propertypal/web-ui/src/takeover/Takeover';
import router from 'next/router';
import React, { FunctionComponent } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useTheme } from 'styled-components';
import featuresTrioData from '../../constants/homepage/featuresTrioData';
import HomeSearchBar from '../search/HomeSearchBar';

interface Props {
  superfeatures: SuperFeatureProperty[];
  recent: Property[];
  popular: Property[];
  takeover: HomepageTakeover | null;
  articles: ConvertedContentHubArticle[] | null;
}

const HomePage: FunctionComponent<Props> = (props) => {
  const images = props.superfeatures.map((row) => row.url || row.image.url);
  const highResImages = props.superfeatures.map((row) => row.url || row.image?.urls['1600x1067:FILL_CROP']);
  const dispatch = useDispatch();
  const asyncDispatch = useAppDispatch();
  const store = useStore<RootState>();
  const { recentSearches, addRecentSearch, removeRecentSearch } = useRecentSearches();
  const theme = useTheme();

  const handleSubmit = async (baseUrl: string, searchText: string, filters: Filters) => {
    dispatch(
      setSearch({
        text: searchText,
        filters: { ...initialState.filters, ...filters },
      }),
    );

    await asyncDispatch(propertySearch('hd'));

    const { properties } = store.getState();

    if (properties.data) {
      addRecentSearch({
        description: properties.data.description,
        text: searchText,
        filters,
      });

      trackGaEvent('nav_click', {
        page: 'HP',
        location: 'Search Box',
        button: filters.category === 'newhomes' ? 'New Homes' : filters.saleType === 'rent' ? 'For Rent' : 'For Sale',
      });

      await router.push(baseUrl + properties.data.url);
    }
  };

  return (
    <>
      {props.takeover && props.takeover.listing ? (
        <Takeover takeover={props.takeover}>
          <HomeSearchBar
            submitText="For Sale"
            showTravelPreview
            showSaleTypeOptions
            onSubmit={handleSubmit}
            recentSearches={recentSearches}
            removeRecentSearch={removeRecentSearch}
          />
        </Takeover>
      ) : (
        <ImageSlideshow
          images={images || []}
          highResImages={highResImages}
          slideDuration={10000}
          superFeatureProperties={props.superfeatures}
          highResThreshold={1600}
        >
          <HomeSearchBar
            submitText="For Sale"
            showTravelPreview
            showSaleTypeOptions
            onSubmit={handleSubmit}
            recentSearches={recentSearches}
            removeRecentSearch={removeRecentSearch}
            // showInstantValuation={props.cfIpCountry !== 'IE'}
          />
        </ImageSlideshow>
      )}

      <SimilarProperties
        properties={props.popular}
        title="Most Viewed Properties"
        titleSize={36}
        subTitle="(Last 7 days)"
        showArrows
        onClick={trackGaLink('nav_click', 'Most Viewed', 'HP')}
      />

      <FeaturesTrio heading={`House Hunting is Easier with ${platform.title}`} data={featuresTrioData} />

      {props?.articles && props?.articles?.length > 0 && (
        <>
          <LatestNews articles={props.articles} />

          {CH_HOME_FEATURE && <GuidesBar />}
        </>
      )}

      {props.recent && (
        <SimilarProperties
          properties={props.recent}
          titleSize={36}
          title="Recently Added Properties"
          showArrows
          onClick={trackGaLink('nav_click', 'Recently Added', 'HP')}
          {...(props?.articles && props?.articles?.length > 0 && !CH_HOME_FEATURE
            ? { arrowBg: theme.white, background: theme.backgroundLightest }
            : {})}
        />
      )}
    </>
  );
};

export default HomePage;
